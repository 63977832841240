<template>
  <template v-if="detail">
    <div v-if="showCustomer">
      <div style="padding:15px;background:#fff;" v-if="Object.keys(salesperson).length > 0">
        <p>
          <small>
            客户经理：{{ salesperson.name }}
          </small>
        </p>
      </div>
    </div>
    <template v-if="extensions.includes('TRANSPORT')">
      <van-cell-group title="收货地址">
        <van-cell title="请设置一个收货地址" is-link v-if="typeof receiver.name === 'undefined'" :to="{ name: 'Receiver', query: { back: '1' } }" />
        <van-cell :title="`${receiver.name} ${receiver.phone}`" :label="receiver.address" is-link v-else :to="{ name: 'Receiver', query: { back: '1' } }" />
      </van-cell-group>
    </template>
    <template v-for="v in detail.stores" :key="v">
      <div style="margin:7px 0 0 0;">
        <van-cell :title="v.storeName" icon="shop-o" />
        <div style="background:#fff;">
          <van-tag type="default" style="margin:10px;">商品</van-tag>
          <template v-for="v2 in v.products" :key="v2">
            <div style="padding:10px;">
              <van-row gutter="10">
                <van-col span="7">
                  <van-image
                    :src="v2.image + '?imageView2/1/w/400/h/400'"
                    width="100%"
                  />
                </van-col>
                <van-col span="17">
                  <p>
                    <small>{{ v2.name }}</small>
                  </p>
                  <div v-if="v2.skuText !== ''" style="margin:5px 0 0 0;">
                    <van-tag type="default">{{ v2.skuText }}</van-tag>
                  </div>
                  <div v-if="v2.bookingDate" style="margin:5px 0 0 0;">
                    <van-tag type="default">{{ v2.bookingDate }}</van-tag>
                  </div>
                  <div v-if="v2.groupon" style="margin:5px 0 0 0;">
                    <van-tag type="danger">拼团</van-tag>
                  </div>
                  <div style="margin:10px 0 0 0;overflow:hidden;">
                    <span style="color:#f44;">&yen;</span>
                    <span style="color:#f44;">{{ v2.price }}</span>
                    <span style="color:#999;">
                      <small>
                        x {{ v2.quantity }}
                      </small>
                    </span>
                  </div>
                </van-col>
              </van-row>
            </div>
          </template>
          <van-tag type="default" style="margin:10px;">订单金额</van-tag>
          <van-cell-group>
            <van-cell title="应付" :value="v.payableAmount" />
            <van-cell title="优惠" :value="v.discountAmount" />
            <van-cell title="折后" :value="v.payAmount" />
          </van-cell-group>
        </div>
      </div>
    </template>
    <template v-if="extensions.includes('BOOKING')">
      <van-cell-group title="联系电话">
        <van-field v-model="contact.name" label="称呼" placeholder="请输入" />
        <van-field v-model="contact.phone" label="手机号" placeholder="请输入" />
      </van-cell-group>
    </template>
    <van-radio-group v-model="checked">
      <van-cell-group title="支付方式">
        <van-cell title="微信支付" clickable @click="checked = 'WECHAT'">
          <template #right-icon>
            <van-radio name="WECHAT" />
          </template>
        </van-cell>
        <van-cell title="余额支付" clickable @click="checked = 'BALANCE'" v-if="memberId">
          <template #right-icon>
            <van-radio name="BALANCE" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div style="height:70px;"></div>
    <van-submit-bar :price="detail.payAmount * 100" button-text="提交订单" @submit="onSubmitOrder" />
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast, Dialog } from 'vant'
import { callWeixinPay } from '@/util/util'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      detail: {},
      receiver: {},
      productsParams: JSON.parse(useRoute.query.products),
      products: [],
      total: 0,
      discount: 0,
      buyerMessage: '',
      buyerName: '',
      buyerPhone: '',
      biz: '',
      contact: {},
      extensions: [],
      reductions: [],
      tradeNumber: '',
      browser: navigator.userAgent.toLowerCase(),
      salesperson: {},
      showCustomer: false,
      groupon: false,
      grouponId: useRoute.query.groupon_team_id ? parseInt(useRoute.query.groupon_team_id) : undefined,
      grouponTeam: {},
      firstProduct: {},
      buyer: {
        user: {}
      },
      member: {},
      discountTotalAmount: 0,
      orderIds: [],
      storeId: Cookies.get('storeId'),
      buyerId: Cookies.get('buyerId'),
      salespersonId: Cookies.get('salespersonId'),
      wechatOpenid: Cookies.get('wechatOpenid'),
      memberId: Cookies.get('memberId'),
      row: {},
      group: [],
      items: [],
      stores: [],
      checked: 'WECHAT'
    })
    const applyPay = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        let payHttpUrl = '/order.applyPay'
        if (res.data.storeMerchant) {
          if (res.data.storeMerchant.applymentState === 'FINISH') {
            payHttpUrl = '/applyMerchantPay'
          }
        }
        post(payHttpUrl, {
          storeId: state.storeId,
          wechatOpenid: state.wechatOpenid,
          tradeNumber: state.tradeNumber,
          paymentMethod: state.checked
        }).then(res => {
          if (res.code === 0) {
            if (state.checked === 'WECHAT') {
              let payParams = {}
              if (payHttpUrl === '/order.applyPay') {
                payParams = res.data.wechatPayParams
              } else {
                payParams = res.data
                payParams.package = res.data.packageValue
              }
              console.log(payParams)
              callWeixinPay(payParams).then(res => {
                if (res.result) {
                  Toast.success('支付成功')
                  useRouter.push({
                    name: 'User'
                  })
                } else {
                  Toast.fail('支付取消')
                }
              })
            } else if (state.checked === 'BALANCE') {
              Toast.success('支付成功')
              useRouter.push({
                name: 'User'
              })
            }
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const onSubmitOrder = () => {
      Toast.loading({ duration: 0, forbidClick: true })
      if (state.tradeNumber !== '') {
        applyPay()
      } else {
        const selected = []
        for (const v of state.productsParams) {
          selected.push({
            productId: v.productId,
            skuId: v.skuId,
            skuDayId: v.skuDayId,
            quantity: v.quantity
          })
        }
        post('/order.create', {
          buyerId: state.buyerId,
          // salespersonId: state.salespersonId,
          items: state.productsParams,
          receiver: {
            ...state.receiver
          },
          contact: {
            ...state.contact
          }
        }).then(res => {
          if (res.code === 0) {
            state.tradeNumber = res.data.tradeNumber
            applyPay()
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const selectReceiver = (value) => {
      console.log(value)
      state.row.groups[state.receiver.groupIndex].products[state.receiver.productIndex].orderAdditional.receiver = value
      state.receiver.show = false
      state.receiver.groupIndex = 0
      state.receiver.productIndex = 0
    }
    // const callWechatPay = () => {
    //   if (state.payment === 'weixin') {
    //     // getPay({
    //     post('/order.getShopping', {
    //       orderIds: state.orderIds,
    //       channel: 'WEIXIN'
    //     }).then(res => {
    //       Toast.clear()
    //       if (res.code === 0) {
    //         callWeixinPay(res.data).then(res => {
    //           if (res.result) {
    //             Toast.success('支付成功')
    //             useRouter.push({ path: '/user' })
    //           } else {
    //             Toast.fail('支付取消')
    //           }
    //         })
    //       } else {
    //         Toast(res.msg)
    //       }
    //     })
    //   }
    // }
    const init = () => {
      Toast.loading({ duration: 0, forbidClick: true })
      post('/order.getPreOrder', {
        buyerId: state.buyerId,
        items: state.productsParams,
        storeOwn: true
      }).then(res => {
        // // state.biz = res.data.orderProducts[0].biz
        // // state.products = res.data.orderProducts
        // // createExtension()
        // state.products = res.data
        // state.stores = unique(state.products, 'storeId')// Array.from(new Set(state.products.map(v => { return v.storeId })))
        // state.extensions = Array.from(new Set(state.products.map(v => { return v.biz })))
        // // computeAll(res.data)
        Toast.clear()
        if (res.code === 0) {
          state.detail = res.data
          state.extensions = Array.from(new Set(res.data.products.map(v => { return v.bizType })))
        } else {
          Dialog.alert({
            title: '提示',
            message: res.msg
          }).then(() => {
            useRouter.go(-1)
          })
        }
      })
      post('/buyer.get', {
        buyerId: state.buyerId
      }).then(res => {
        state.buyer = res.data
      })
      post('/receiver.list', {
        buyerId: state.buyerId
      }).then(res => {
        if (res.data.content.length > 0) {
          state.receiver = res.data.content[0]
        }
      })
      if (state.storeId) {
        post('/store.get', {
          storeId: state.storeId
        }).then(res => {
          state.showCustomer = res.data.isswitch
          if (state.showCustomer) {
            if (state.buyerId) {
              post('/customer.get', {
                buyerId: state.buyerId,
                storeId: state.storeId
              }).then(res => {
                if (res.code === 0) {
                  state.salesperson.name = res.data.staffName
                }
              })
            }
          }
        })
      }
    }
    watch(() => state.products, (to, previous) => {
      console.log(123, to)
    }, { deep: true })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmitOrder,
      callWeixinPay,
      selectReceiver
    }
  }
}
</script>

<style scoped lang="less">
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>
